// Util
import { ClientAccountUtil } from '@/utils/clientAccountUtil';
import { ImportUtil } from '@/utils/importUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// Others
import config from '@/config/env-constants';
import _ from 'lodash';


function hasValidAssetTypes(assets, assetTypesObj) {
	let isValid = true;

	let assetTypeNames = _.map(assetTypesObj, 'name');

	for (let asset of assets) {
		let assetTypeName = asset.assetType ? asset.assetType : '';
		if (!assetTypeNames.includes(assetTypeName)) {
			isValid = false
			break;
		}
	}

	return isValid;
}

function getInvalidAssetTypes(assets, assetTypesObj) {
	let invalidAssetTypes = [];

	let assetTypeNames = _.map(assetTypesObj, 'name');

	for (let asset of assets) {
		let assetTypeName = asset.assetType ? asset.assetType : '';
		if (!assetTypeNames.includes(assetTypeName)) {
			invalidAssetTypes.push(assetTypeName);
		}
	}

	return _.join(invalidAssetTypes, ', ');
}

function hasInvalidExpectedQuantity(assets) {
	for (let asset of assets) {
		let expectedQuantity = asset.expectedQuantity ? parseInt(asset.expectedQuantity) : 0;
		if (expectedQuantity <= 0) {
			return true;
		}
	}
	return false;
}

function getInvalidExpectedQuantities(assets) {
	let invalidQuantities = [];

	for (let asset of assets) {
		let assetTypeName = asset.assetType ? asset.assetType : '';
		let expectedQuantity = asset.expectedQuantity ? parseInt(asset.expectedQuantity) : 0;
		if (expectedQuantity < 0) {
			invalidQuantities.push(assetTypeName + ' ' + expectedQuantity);
		}
	}

	if (invalidQuantities.length > 1) {
		return _.join(invalidQuantities, ', ');
	} else {
		return invalidQuantities[0];
	}
}

function hasInvalidActualQuantity(assets) {
	for (let asset of assets) {
		let expectedQuantity = asset.expectedQuantity ? parseInt(asset.expectedQuantity) : 0;
		let actualQuantity = asset.actualQuantity ? parseInt(asset.actualQuantity) : 0;
		if (actualQuantity < 0 || expectedQuantity < actualQuantity) {
			return true;
		}
	}
	return false;
}

function getNegativeActualQuantities(assets) {
	let invalidQuantities = [];
	for (let asset of assets) {
		let assetTypeName = asset.assetType ? asset.assetType : '';
		let actualQuantity = asset.actualQuantity ? parseInt(asset.actualQuantity) : 0;
		if (actualQuantity <= 0) {
			invalidQuantities.push(assetTypeName + ' ' + actualQuantity);
		}
	}

	if (invalidQuantities.length > 1) {
		return _.join(invalidQuantities, ', ');
	} else {
		return invalidQuantities[0];
	}
}

function getExceededActualQuantities(assets) {
	let invalidQuantities = [];
	for (let asset of assets) {
		let assetTypeName = asset.assetType ? asset.assetType : '';
		let expectedQuantity = asset.expectedQuantity ? parseInt(asset.expectedQuantity) : 0;
		let actualQuantity = asset.actualQuantity ? parseInt(asset.actualQuantity) : 0;
		if (expectedQuantity < actualQuantity) {
			invalidQuantities.push(assetTypeName + ' ' + actualQuantity + '/' + expectedQuantity);
		}
	}

	if (invalidQuantities.length > 1) {
		return _.join(invalidQuantities, ', ');
	} else {
		return invalidQuantities[0];
	}
}

function exceedMaximumAssetTypes(dispatch, isNewAsset = false) {
	let assets = dispatch.assets ? dispatch.assets : [];
	if (isNewAsset) {
		return _.size(assets) >= config.maxAssetTypePerDispatch;
	} else {
		return _.size(assets) > config.maxAssetTypePerDispatch;
	}
}

function getDefaultDispatchObj() {
	return {
		dispatchId: '',
		source: {},
		destination: {},
		transportation: { ...config.dispatchTransportDefaultValue },
		driver: { ...config.dispatchDriverDefaultValue },
		newTransportationToAdd: {},
		assets: [],
		status: 'Draft',
		notes: '',
		fromInactiveNode: 'false',
		toInactiveNode: 'false',
		creationSource: config.dispatchCreationSource.DISPATCH,
	};
}

function getDefaultDispatchAssetObj(assetType) {
	return {
		assetType: assetType.name,
		assetTypeId: assetType.id,
		expectedQuantity: 0,
		actualQuantity: 0,
		totalRemaining: 0
	}
}

function cleanupFields(dispatch) {
	let cleanedObj = { ...dispatch };

	delete cleanedObj['Source'];
	delete cleanedObj['Destination'];
	delete cleanedObj['Trucker'];
	delete cleanedObj['Truck Assistant'];
	delete cleanedObj['Dispatched'];
	delete cleanedObj['Received'];
	delete cleanedObj['Date Created'];
	delete cleanedObj['Date Updated'];
	delete cleanedObj['Date Deployed'];
	delete cleanedObj['Date Received'];
	delete cleanedObj['Date Cancelled'];
	delete cleanedObj['Date Rejected'];
	delete cleanedObj['newCompanyToAdd'];
	delete cleanedObj['newTransportationToAdd'];
	delete cleanedObj['_showDetails'];

	return cleanedObj;
}

function isExistingAssetType(assets, assetTypeId) {
	return (
		!_.isEmpty(assets) &&
		_.find(assets, { 'assetTypeId': assetTypeId })
	);
}

function getTotalExpectedQuantity(assets) {
	let expectedQuantity = 0;

	if (!_.isEmpty(assets)) {
		_.forEach(assets, asset => {
			expectedQuantity += parseInt(asset.expectedQuantity);
		});
	}

	return expectedQuantity;
}

function getTotalActualQuantity(assets) {
	let actualQuantity = 0;

	if (!_.isEmpty(assets)) {
		_.forEach(assets, asset => {
			actualQuantity += parseInt(asset.actualQuantity);
		});
	}

	return actualQuantity;
}

function hasAssetType(assets, assetTypeId) {
	let dispatchAsset = _.find(assets, o => {
		return o.assetTypeId === assetTypeId;
	});
	return !_.isEmpty(dispatchAsset);
}


export const DispatchUtil = {

	getCompanyIdForUserOptions(source, destination) {
		if (source.isActive === 'true') {
			return source.id;
		} else if (source.isActive === 'false' && destination.isActive === 'true') {
			return destination.id;
		}
		return null;
	},

	validate(dispatch, param) {
		let errors = [];

		const usersObj = param.usersObj;
		const companiesObj = param.companiesObj;
		const storageLocationsObj = param.storageLocationsObj;
		const connectionsObj = param.connectionsObj;
		const assetTypesObj = param.assetTypesObj;
		const transportationsObj = param.transportationsObj;
		const clientAccountsObj = param.clientAccountsObj;

		const status = dispatch.status ? dispatch.status : '';
		const notes = dispatch.notes ? dispatch.notes : '';
		const dateDeployed = dispatch.dateDeployed ? dispatch.dateDeployed : '';
		const deployedBy = dispatch.deployedBy ? dispatch.deployedBy : '';
		const dateReceived = dispatch.dateReceived ? dispatch.dateReceived : '';
		const receivedBy = dispatch.receivedBy ? dispatch.receivedBy : '';

		let source = dispatch.source ? dispatch.source : {};
		const sourceCompany = source.company ? source.company : '';
		const sourceLoc = source.storageLocation ? source.storageLocation : '';

		let destination = dispatch.destination ? dispatch.destination : {};
		const destinationCompany = destination.company ? destination.company : '';
		const destinationLoc = destination.storageLocation ? destination.storageLocation : '';

		let transportation = dispatch.transportation ? dispatch.transportation : {};
		const transportationCompany = transportation.company ? transportation.company : '';
		const plateNo = transportation.plateNo ? transportation.plateNo : '';

		let driver = dispatch.driver ? dispatch.driver : {};
		const driverName = driver.name ? driver.name : '';
		const driverCompany = driver.company ? driver.company : '';
		const assistants = driver.assistants ? driver.assistants : '';

		const assets = dispatch.assets;
		const accountNo = dispatch.accountNo;


		let errPrefix = 'Dispatch from "' + sourceCompany + '" to "' + destinationCompany + '"';

		// Status
		if (!status && status.length === 0) {
			errors.push(errPrefix + ' has no status. This is required.');
		} else if (status !== 'Received') {
			errors.push(errPrefix + ' has invalid dispatch status. Received is only the valid dispatch status for import.');
		}

		// Notes / Remarks
		if (!notes && notes.length === 0) {
			errors.push(errPrefix + ' has no notes/remarks. This is required.');
		} else if (!config.remarksRegex.test(notes)) {
			errors.push(errPrefix + ' has invalid notes/remarks. Notes/Remarks should only contain letters, numbers and these special characters: &\'"-,/():;!*[]');
		}

		// Date Deployed
		if (!dateDeployed && dateDeployed.length === 0) {
			errors.push(errPrefix + ' has no date deployed. This is required.');
		} else if (!ImportUtil.isValidDate(dateDeployed)) {
			errors.push(errPrefix + ' has invalid date deployed. Follow the format mm-dd-yyyy or mm/dd/yyyy.');
		} else if (dateDeployed && ValidationUtil.isFutureDate(dateDeployed)) {
			errors.push(errPrefix + ' has invalid date deployed. Date must not be future dates.');
		}

		// Date Received
		if (!dateReceived && dateReceived.length === 0) {
			errors.push(errPrefix + ' has no date received. This is required for "Received" dispatch.');
		} else if (dateReceived && dateReceived.length > 0 && !ImportUtil.isValidDate(dateReceived)) {
			errors.push(errPrefix + ' has invalid date received. Follow the format mm-dd-yyyy or mm/dd/yyyy.');
		} else if (ValidationUtil.isFutureDate(dateReceived)) {
			errors.push(errPrefix + ' has invalid date received. Date must not be future dates.');
		} else if (new Date(dateReceived) < new Date(dateDeployed)) {
			errors.push(errPrefix + ' has invalid date received. Date received should not be before date deployed.');
		}

		// Source and Destination
		if (!sourceCompany || sourceCompany.length === 0) {
			errors.push(errPrefix + ' has no source company. This is required.');
		} else if (!companiesObj[sourceCompany]) {
			errors.push(errPrefix + ' has a source company, ' + sourceCompany + ', that you don\'t have access to.');
		}

		if (!sourceLoc || sourceLoc.length === 0) {
			errors.push(errPrefix + ' has no source storage location. This is required.');
		} else if (!storageLocationsObj[sourceLoc]) {
			errors.push(errPrefix + ' has a source storage location, ' + sourceLoc + ', that you don\'t have access to.');
		}

		if (!destinationCompany || destinationCompany.length === 0) {
			errors.push(errPrefix + ' has no destination company. This is required.');
		} else if (!companiesObj[destinationCompany]) {
			errors.push(errPrefix + ' has a destination company, ' + destinationCompany + ', that you don\'t have access to.');
		}

		if (!destinationLoc || destinationLoc.length === 0) {
			errors.push(errPrefix + ' has no destination storage location. This is required.');
		} else if (!storageLocationsObj[destinationLoc]) {
			errors.push(errPrefix + ' has a destination storage location, ' + destinationLoc + ', that you don\'t have access to.');
		}

		if (sourceCompany.length > 0 && destinationCompany.length > 0 && sourceLoc.length > 0 && destinationLoc.length > 0) {
			if (sourceCompany === destinationCompany && sourceLoc === destinationLoc) {
				errors.push(errPrefix + ' has the same source (' + sourceCompany + '-' + sourceLoc + ') and destination (' + destinationCompany + '-' + destinationLoc + '). This is not allowed.');
			} else if (sourceCompany !== destinationCompany && (!connectionsObj[sourceCompany] || !connectionsObj[sourceCompany].includes(destinationCompany))) {
				errors.push(errPrefix + ' has source and destination company that don\'t have connections.');
			}
		}

		// Transportation
		if (!transportationCompany || transportationCompany.length === 0) {
			errors.push(errPrefix + '\' vehicle has no assigned company. This is required.');
		} else if (!companiesObj[transportationCompany]) {
			errors.push(errPrefix + '\' vehicle company, ' + transportationCompany + ', that you don\'t have access to.');
		}

		if (!plateNo && plateNo.length === 0) {
			errors.push(errPrefix + '\' vehicle has no plate no. This is required.');
		} else if (!ValidationUtil.isValidPlateNo(plateNo)) {
			errors.push(errPrefix + '\' vehicle has an invalid plate no.');
		} else if (!transportationsObj[plateNo]) {
			errors.push(errPrefix + '\' vehicle plate no, ' + plateNo + ', is non-existent. Add this vehicle first in the Account Setup -> Transportation.');
		}

		if (!driverName || driverName.length === 0) {
			errors.push(errPrefix + ' has no driver. This is required.');
		} else if (!ValidationUtil.isAlphaWithWhiteSpace(driverName)) {
			errors.push(errPrefix + ' has invalid driver name.');
		}
		if (!driverCompany || driverCompany.length === 0) {
			errors.push(errPrefix + '\'s driver has no assigned company. This is required.');
		}

		if (assistants.length > 0 && !ValidationUtil.isAlphaWithWhiteSpaceAndComma(assistants)) {
			errors.push(errPrefix + ' has invalid assistant(s) name.');
		}

		// Deployed By
		if (!deployedBy && deployedBy.length === 0) {
			errors.push(errPrefix + ' has no dispatcher. This is required.');
		} else if (!usersObj[deployedBy]) {
			errors.push(errPrefix + ' has a dispatcher, ' + deployedBy + ', that you don\'t have access to.');
		}

		// Received By
		if (status === 'Received' && !receivedBy && receivedBy.length === 0) {
			errors.push(errPrefix + ' has no receiver. This is required.');
		} else if (status === 'Received' && !usersObj[receivedBy]) {
			errors.push(errPrefix + ' has a receiver, ' + receivedBy + ', that you don\'t have access to.');
		}

		if (!hasValidAssetTypes(assets, assetTypesObj)) {
			errors.push(errPrefix + ' has invalid asset types: ' + getInvalidAssetTypes(assets, assetTypesObj));
		}
		if (exceedMaximumAssetTypes(dispatch)) {
			errors.push(`${errPrefix} has exceeded the allowed number of asset types (${config.maxAssetTypePerDispatch}) per dispatch.`);
		}

		if (hasInvalidExpectedQuantity(assets)) {
			let invalidQuantities = getInvalidExpectedQuantities(assets);
			errors.push(errPrefix + ' has invalid expected quantity. Expected quantity should be greater than 0. Please check these asset(s): ' + invalidQuantities);
		}

		if (hasInvalidActualQuantity(assets)) {
			let negativeQuantities = getNegativeActualQuantities(assets);
			if (negativeQuantities && negativeQuantities.length > 0) {
				errors.push(errPrefix + ' has invalid actual quantity. Actual quantity should be greater than 0. Please check these asset(s): ' + negativeQuantities);
			}

			let exceededQuantities = getExceededActualQuantities(assets);
			if (exceededQuantities && exceededQuantities.length > 0) {
				errors.push(errPrefix + ' has invalid actual quantity. Actual quantity should not be greater than expected quantity. Please check these asset(s): ' + exceededQuantities);
			}
		}

		let sourceId = companiesObj[sourceCompany].id;
		let destinationId = companiesObj[destinationCompany].id;
		if (ClientAccountUtil.hasActiveClientAccount(clientAccountsObj, sourceId, destinationId)) {
			let filteredAccountsObj = ClientAccountUtil.getActiveClientAccounts(clientAccountsObj, sourceId, destinationId);
			if (!filteredAccountsObj[accountNo]) {
				errors.push(errPrefix + ' has a client account, ' + accountNo + ', that you don\'t have access to.');
			}
		}

		return errors;
	},

	isActiveTransportDriver: (dispatch) => {
		return (dispatch.driver && dispatch.driver.userId && dispatch.driver.userId.length > 0);
	},

	isFromInactiveSource: (dispatch) => {
		return (dispatch.fromInactiveNode && dispatch.fromInactiveNode === 'true');
	},

	isInactiveCompany(company) {
		return company && company.isActive === 'false' ? 'true' : 'false';
	},

	isValidTransportation(tranportation) {
		let { company, plateNo } = tranportation;

		if (!_.isEmpty(tranportation)) {
			return !(!company || !plateNo);
		}

		return true;
	},

	getCompanyLocationDisplay(item) {
		return item.company + ' (' + item.storageLocation + ')';
	},

	getDriverLicenseObj(driver) {
		if (driver && !_.isEmpty(driver.license)) {
			return {
				url: driver.license.url,
				_isNew: false
			}
		}
		return {};
	},

	hasValidAssetTypes,
	getInvalidAssetTypes,
	exceedMaximumAssetTypes,
	getDefaultDispatchObj,
	getDefaultDispatchAssetObj,
	cleanupFields,
	isExistingAssetType,
	getTotalExpectedQuantity,
	getTotalActualQuantity,
	hasAssetType
}
